<template>
	<div class="flask-block">
		<p class="flask-block-early-access-msg">
			Are you an educational organization representative? Don’t miss a chance to participate in our
			<span class="early-access-bold">Early Access Program</span> - you’ll get up to 30 yearly full licenses for
			500&nbsp;&euro; only! Click
			<a
				class="early-access-link"
				href="https://docs.google.com/document/d/1cLmqi1wzO1VWUeGpPXFBQEpnawuwQy2YyKt_ps5DTRg/edit?usp=sharing"
				target="_blank"
				>here</a
			>
			to know more and <button @click.stop="$emit('showFeedback')" class="early-access-btn">here</button> to get
			started immediately.
		</p>
		<h3 class="flask-block-title">Chemistry lab software for your VR headset</h3>
		<h4 class="flask-block-subtitle">There is always room for the experiment</h4>
		<div class="flask-block-icon-sets">
			<div v-for="(icons, sidx) in iconSets" :key="`icon-set-${sidx}`" class="flask-block-icon-sets-set">
				<div
					v-for="(icon, idx) in icons"
					:key="`icon-${idx}`"
					:ref="icon"
					@mouseover="mouseoverItem(icon)"
					@mouseout="mouseoutItem()"
					class="flask-block-icon-sets-set-icon"
				>
					<component :is="pascalToKebab(icon)" />
				</div>
			</div>
		</div>
		<div class="flask-block-slug">
			<span>{{ selectedItem.text }}</span>
		</div>
	</div>
</template>

<script>
const NOTEPAD_ICON = 'NotepadIcon';
const VR_HEADSET_ICON = 'VrHeadsetIcon';
const FLASKS_ICON = 'FlasksIcon';
const LAPTOP_ICON = 'LaptopIcon';
const PUZZLE_ICON = 'PuzzleIcon';

import FlasksIcon from '@/components/icons/FlasksIcon.vue';
import VrHeadsetIcon from '@/components/icons/VrHeadsetIcon.vue';
import LaptopIcon from '@/components/icons/LaptopIcon.vue';
import NotepadIcon from '@/components/icons/NotepadIcon.vue';
import PuzzleIcon from '@/components/icons/PuzzleIcon.vue';

export default {
	data() {
		return {
			selectedIdx: 0,
			prevIdx: 0,
			interval: null,
			iconSets: [[FLASKS_ICON], [VR_HEADSET_ICON, LAPTOP_ICON], [NOTEPAD_ICON, PUZZLE_ICON]],
		};
	},
	components: {
		FlasksIcon,
		VrHeadsetIcon,
		LaptopIcon,
		NotepadIcon,
		PuzzleIcon,
	},
	methods: {
		pascalToKebab(str) {
			return (
				str[0].toLowerCase() + str.slice(1, str.length).replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`)
			);
		},
		slideToNext(selectedIdx = null) {
			if (this.selectedIdx < this.items.length - 1) {
				this.prevIdx = this.selectedIdx;
				this.selectedIdx = selectedIdx !== null ? selectedIdx : ++this.selectedIdx;
			} else {
				this.prevIdx = this.items.length - 1;
				this.selectedIdx = selectedIdx !== null ? selectedIdx : 0;
			}
			this.$refs[this.prevItem.ref][0].classList.remove('active-icon');
			this.$refs[this.selectedItem.ref][0].classList.add('active-icon');
		},
		mouseoverItem(ref) {
			clearInterval(this.interval);
			this.slideToNext(this.items.indexOf(this.items.find(it => it.ref === ref)));
		},
		mouseoutItem() {
			this.interval = setInterval(this.slideToNext, 3000);
		},
	},
	computed: {
		items() {
			if (this.$i18n.locale === 'ru') {
				return [
					{
						text: 'Комплекты задач для школ и вузов',
						ref: NOTEPAD_ICON,
					},
					{
						text: 'Работает на распространенных VR шлемах',
						ref: VR_HEADSET_ICON,
					},
					{
						text: 'Достоверная физико-химическая модель',
						ref: FLASKS_ICON,
					},
					{
						text: 'Просто назначать и проверять задания',
						ref: LAPTOP_ICON,
					},
					{
						text: 'Пополняемая база методических материалов и задач',
						ref: PUZZLE_ICON,
					},
				];
			}

			return [
				{
					text: 'Ready to use chemistry lab tasks',
					ref: NOTEPAD_ICON,
				},
				{
					text: 'Supports most of the popular VR headsets',
					ref: VR_HEADSET_ICON,
				},
				{
					text: 'Physicochemical model calculates the reaction result',
					ref: FLASKS_ICON,
				},
				{
					text: 'Easy-to-use teacher dashboard ',
					ref: LAPTOP_ICON,
				},
				{
					text: 'Zero-coded custom tasks builder',
					ref: PUZZLE_ICON,
				},
			];
		},
		selectedItem() {
			return this.items[this.selectedIdx];
		},
		prevItem() {
			return this.items[this.prevIdx];
		},
	},
	mounted() {
		this.$refs[this.selectedItem.ref][0].classList.add('active-icon');
		this.interval = setInterval(this.slideToNext, 3000);
	},
	beforeUnmount() {
		clearInterval(this.interval);
	},
};
</script>

<style lang="scss" scoped>
.flask-block {
	&-early-access-msg {
		@extend %roboto-headline-5;
		color: $text-secondary;
		text-align: center;
		max-width: 85rem;
		border: 1px solid $text-secondary;
		border-radius: 0.5rem;
		padding: 0.5rem;
		margin: 0 auto 1rem;

		@include media-breakpoint-down(sm) {
			margin-top: -2rem;
		}

		.early-access-bold {
			@extend %roboto-headline-5-bold;
		}

		.early-access-btn,
		.early-access-link {
			background: none;
			border: none;
			padding: 0;
			font: inherit;
			color: inherit;
			text-decoration: underline;
		}
	}

	&-title {
		@extend %roboto-headline-3;
		margin-bottom: 1rem;
		color: $primary-dark;
		text-align: center;

		@media (max-width: 26.563rem) {
			margin-left: -1.25rem;
			margin-right: -1.25rem;
		}
	}

	&-subtitle {
		@extend %roboto-headline-4;
		margin-bottom: 3rem; // temporary change (reason: flask block disabled)
		color: $text-secondary;
		text-align: center;

		@include media-breakpoint-down(md) {
			margin-bottom: 1.75rem; // temporary change (reason: flask block disabled)
		}
	}

	&-icon-sets {
		position: relative;
		display: none; // temporary change (reason: flask block disabled)
		margin-bottom: 16rem;

		@include media-breakpoint-down(lg) {
			margin-bottom: 12rem;
		}

		@include media-breakpoint-down(md) {
			display: block;
		}

		@include media-breakpoint-down(sm) {
			margin-bottom: 6.5rem;
		}

		&:before {
			content: '';
			position: absolute;
			top: 2rem;
			left: 50%;
			transform: translate(-50%);
			width: 45rem;
			height: 45rem;
			background-image: url('@/assets/images/flask.png');
			background-repeat: no-repeat;
			background-size: cover;

			@include media-breakpoint-down(lg) {
				width: 37.5rem;
				height: 37.5rem;
			}

			@include media-breakpoint-down(sm) {
				top: 1rem;
				width: 17.9rem;
				height: 17.9rem;
			}
		}

		&-set {
			display: flex;
			align-items: center;
			margin-left: auto;
			margin-right: auto;
			height: 5.75rem;

			&:first-child {
				justify-content: center;
			}

			&:not(first-child) {
				justify-content: space-between;
			}

			@include media-breakpoint-down(lg) {
				height: 5.75rem;
			}

			@include media-breakpoint-down(sm) {
				height: 2.56rem;
			}

			&:not(:last-child) {
				margin-bottom: 1.25rem;

				@include media-breakpoint-down(sm) {
					margin-bottom: 1rem;
				}
			}

			&:nth-child(2) {
				width: 24.975rem;

				@include media-breakpoint-down(lg) {
					width: 22.25rem;
				}

				@include media-breakpoint-down(sm) {
					width: 13.5rem;
				}
			}

			&:nth-child(3) {
				width: 28.125rem;

				@include media-breakpoint-down(lg) {
					width: 26.25rem;
				}

				@include media-breakpoint-down(sm) {
					width: 14.75rem;
				}
			}

			&-icon {
				z-index: 2;

				svg {
					scale: 0.8;
				}

				@include media-breakpoint-down(lg) {
					svg {
						scale: 0.8;
					}
				}

				@include media-breakpoint-down(sm) {
					svg {
						scale: 0.36;
					}
				}
			}
		}

		.active-icon {
			svg {
				color: $primary-dark;
			}
		}
	}

	&-slug {
		display: none; // temporary change (reason: flask block disabled)
		justify-content: center;
		width: 41.875rem;
		margin: 0 auto;
		border-radius: 3.125rem;
		padding: 1rem 1.5rem;
		background-color: $text-primary;

		@include media-breakpoint-down(lg) {
			width: 35.5rem;
		}

		@include media-breakpoint-down(md) {
			display: flex;
		}

		@include media-breakpoint-down(sm) {
			width: 17.25rem;
			padding: 0.5rem;
		}

		span {
			@extend %jura-tabs;
			color: $text-white;
		}
	}
}
</style>
