<template>
	<div class="devices-block">
		<h3 class="devices-block-title">{{ $t('title') }}</h3>
		<div v-for="(block, idx) in blocks" :key="`block-${idx}`" class="devices-block-block">
			<div class="devices-block-block-text">
				<h4 class="devices-block-block-text-title">{{ $t(`subtitle-${idx + 1}`) }}</h4>
				<ul class="devices-block-block-text-list">
					<li v-for="(item, lidx) in block.items" :key="`item-${lidx}`">
						{{ item }}
					</li>
				</ul>
			</div>
			<img class="devices-block-block-image" :src="block.image" :alt="block.title" />
		</div>
	</div>
</template>

<i18n>
    {
        "ru": {
            "title": "Поддерживаемое оборудование",
            "subtitle-1": "Автономные шлемы виртуальной реальности:",
            "subtitle-2": "«VR-ready» компьютер и шлем виртуальной реальности:"
        },
        "en": {
            "title": "Supported VR headsets",
            "subtitle-1": "Standalone VR headsets",
            "subtitle-2": "PC VR headsets"
        }
    }
</i18n>

<script>
export default {
	data() {
		return {
			devicesRu: {
				standalone: [
					'Pico Neo 3 / Link / Pro / Pro Eye',
					'Pico 4 / Pro / Enterprise',
					'HTC Vive Focus Plus',
					'HTC Vive Focus 3',
					'HTC Vive XR Elite',
					'Oculus Quest 1/2/3',
				],
				pc: [
					'HTC Vive',
					'HTC Vive Pro / Pro Eye',
					'HTC Vive Pro 2',
					'HTC Vive Cosmos / Cosmos Elite',
					'Oculus Rift / Rift S',
					'HP Reverb / Reverb G2',
					'Dell Visor',
					'Samsung HMD Odyssey+',
					'Acer AH101',
				],
			},
			devicesEn: {
				standalone: [
					'Oculus (Meta) Quest 2/3/3s',
					'Pico Neo 3 series',
					'Pico 4 series',
					'Vive Focus 3',
					'VIVE XR Elite',
				],
				pc: [
					'HTC Vive',
					'HTC Vive Pro / Pro Eye',
					'HTC Vive Pro 2',
					'HTC Vive Cosmos / Cosmos Elite',
					'Oculus Rift / Rift S',
					'HP Reverb / Reverb G2',
					'Dell Visor',
					'Samsung HMD Odyssey+',
					'Acer AH101',
				],
			},
		};
	},
	computed: {
		blocks() {

			const devices = this.$i18n.locale === 'ru' ? this.devicesRu: this.devicesEn; 
			let blocks = [
				{
					items: devices.standalone,
					image: require('@/assets/images/pico-neo-4.jpg'),
				},
				{
					items: devices.pc,
					image: require('@/assets/images/pc-vr.jpg'),
				},
			];
			// if (this.$i18n.locale === 'en') {
			// 	blocks[0].items.splice(blocks[0].items.indexOf('Oculus Quest 2'), 1);
			// 	blocks[0].items.unshift('Meta (Oculus) Quest 1/2');
			// }

			return blocks;
		},
	},
};
</script>

<style lang="scss" scoped>
.devices-block {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	&-title {
		@extend %roboto-headline-3;
		text-align: center;
		color: $primary-dark;
		margin-bottom: 2rem;

		@include media-breakpoint-down(sm) {
			margin-bottom: 3rem;
		}
	}

	&:before {
		content: '';
		position: absolute;
		z-index: -1;
		top: 55%;
		left: 0;
		width: 39.75rem;
		height: 42.125rem;
		transform: translateX(-50%) translateY(-50%);
		background-image: url('@/assets/images/gradient-gear.svg');
		background-size: contain;
		background-repeat: no-repeat;

		@include media-breakpoint-down(xxl) {
			width: 19.875rem;
			height: 21rem;
		}

		@include media-breakpoint-down(sm) {
			width: 13.25rem;
			height: 14.125rem;
		}
	}

	&-block {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		max-width: 70rem;
		height: 33rem;

		@include media-breakpoint-down(lg) {
			height: 27.75rem;
			max-width: unset;
		}

		@include media-breakpoint-down(sm) {
			height: initial;
			justify-content: center;
		}

		&:first-of-type {
			margin-bottom: 5rem;

			@include media-breakpoint-down(lg) {
				margin-bottom: 4rem;
			}

			@include media-breakpoint-down(sm) {
				margin-bottom: 2rem;
			}
		}

		&-text {
			width: 100%;

			&-title {
				@extend %roboto-headline-4;
				text-align: center;
				color: $text-primary;
				margin-bottom: 1rem;
			}

			&-list {
				@extend %roboto-body-1;
				color: $text-secondary;
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-bottom: 0;
				padding-left: 0;

				li:not(:last-child) {
					margin-bottom: 1rem;
				}
			}
		}

		&-image {
			width: 33.625rem;
			height: auto;

			@include media-breakpoint-down(lg) {
				width: 20.875rem;
			}

			@include media-breakpoint-down(sm) {
				display: none;
			}
		}
	}
}
</style>
