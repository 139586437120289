<template>
	<div id="pirces-block" class="price-en">
		<h1 class="price-en-title">Prices</h1>
		<div class="price-en-text">
			<div class="price-en-text-block">
				<p>
					A standard price for Vic’s Science Studio software package is
					<span class="text-bold">240&nbsp;&euro; per year per user.</span>
				</p>
				<p>However, individual conditions may apply depending on your needs.</p>
			</div>
			<div class="price-en-text-block">
				<p>
					Also you can apply for our <span class="text-bold">limited Early Access Program</span> and load up
					to 30 headsets in your institution with yearly licenses just for
					<span class="text-bold">500&nbsp;&euro;</span>
					(<a
						class="early-access-link"
						href="https://docs.google.com/document/d/1cLmqi1wzO1VWUeGpPXFBQEpnawuwQy2YyKt_ps5DTRg/edit?usp=sharing"
						target="_blank"
						>T&C apply</a
					>).
				</p>
				<p>
					<button @click.stop="$emit('showFeedback')" class="early-access-btn">Contact us</button> for more
					information
				</p>
			</div>
		</div>
		<landing-button @click.stop="$emit('showFeedback')" outline>Get started</landing-button>
	</div>
</template>

<script>
import LandingButton from '@/components/LandingButton.vue';

export default {
	components: { LandingButton },
};
</script>

<style lang="scss" scoped>
.price-en {
	@extend %roboto-headline-4;
	text-align: center;
	color: $text-secondary;
	width: 100%;

	&-title {
		@extend %roboto-headline-3;
		color: $primary-dark;
		margin-bottom: 2.25rem;

		@include media-breakpoint-down(sm) {
			margin-bottom: 3rem;
		}
	}

	&-text {
		margin-left: auto;
		margin-right: auto;
		max-width: 86.25rem;

		&-block {
			margin-bottom: 2.5rem;

			@include media-breakpoint-down(sm) {
				margin-bottom: 2rem;
			}

			& > p {
				@extend %roboto-headline-4;
				text-align: center;
				margin-bottom: 0;
			}

			.text-bold {
				@extend %roboto-headline-4-bold;
			}

			.early-access-btn,
			.early-access-link {
				background: none;
				border: none;
				padding: 0;
				font: inherit;
				color: inherit;
				text-decoration: underline;
			}
		}
	}
}
</style>
