<template>
	<div class="landing">
		<navbar @showFeedback="showFeedback = true" />
		<flask-block @showFeedback="showFeedback = true" />
		<video-block />
		<about-block />
		<devices-block />
		<price @showFeedback="showFeedback = true" />
		<contact-block />
		<footbar />
		<feedback-modal v-model:show="showFeedback" @submit="handleSubmit" />
		<confirm-modal v-model:show="showConfirm" />
	</div>
</template>

<script>
import Navbar from '@/components/navbar/en/Navbar.vue';
import FlaskBlock from '@/views/landing/en/FlaskBlock.vue';
import VideoBlock from '@/views/landing/common/VideoBlock.vue';
import AboutBlock from './AboutBlock.vue';
import DevicesBlock from '@/views/landing/common/DevicesBlock.vue';
import Price from './Price.vue';
import ContactBlock from './ContactBlock.vue';
import Footbar from '@/components/footbar/en/Footbar.vue';
import FeedbackModal from '@/components/modals/feedback-modal/en/FeedbackModal';
import ConfirmModal from '@/components/modals/ConfirmModal';

export default {
	data() {
		return {
			showFeedback: false,
			showConfirm: false,
		};
	},
	methods: {
		handleSubmit() {
			this.showConfirm = true;
		},
	},
	components: {
		Navbar,
		FlaskBlock,
		VideoBlock,
		AboutBlock,
		DevicesBlock,
		Price,
		ContactBlock,
		Footbar,
		FeedbackModal,
		ConfirmModal,
	},
};
</script>

<style lang="scss" scoped>
.landing {
	padding: 0 1.25rem;
	overflow-x: hidden;

	&-navbar {
		max-width: 117rem;
		margin: 1.125rem auto 2rem auto;

		@include media-breakpoint-down(lg) {
			margin-bottom: 2rem;
		}

		@include media-breakpoint-down(sm) {
			margin-bottom: 3rem;
		}
	}

	.video-block,
	.devices-block,
	.about-block-en,
	.price-en,
	.contacts-block-en {
		margin-bottom: 9rem;

		@include media-breakpoint-down(lg) {
			margin-bottom: 8rem;
		}

		@include media-breakpoint-down(sm) {
			margin-bottom: 4rem;
		}
	}
	
	.flask-block {
		@include media-breakpoint-down(md) {
			margin-bottom: 8rem;
		}

		@include media-breakpoint-down(sm) {
			margin-bottom: 4rem;
		}
	}
}
</style>
